import { isHTMLElement } from '@fluentui/react-utilities';
import { KEYBORG_FOCUSIN, createKeyborg, disposeKeyborg } from 'keyborg';
import { FOCUS_VISIBLE_ATTR } from './constants';
/**
 * @internal
 * @param scope - Applies the ponyfill to all DOM children
 * @param targetWindow - window
 */ export function applyFocusVisiblePolyfill(scope, targetWindow) {
    if (alreadyInScope(scope)) {
        // Focus visible polyfill already applied at this scope
        return ()=>undefined;
    }
    const state = {
        current: undefined
    };
    const keyborg = createKeyborg(targetWindow);
    // When navigation mode changes remove the focus-visible selector
    keyborg.subscribe((isNavigatingWithKeyboard)=>{
        if (!isNavigatingWithKeyboard && state.current) {
            removeFocusVisibleClass(state.current);
            state.current = undefined;
        }
    });
    // Keyborg's focusin event is delegated so it's only registered once on the window
    // and contains metadata about the focus event
    const keyborgListener = (e)=>{
        if (state.current) {
            removeFocusVisibleClass(state.current);
            state.current = undefined;
        }
        if (keyborg.isNavigatingWithKeyboard() && isHTMLElement(e.target) && e.target) {
            // Griffel can't create chained global styles so use the parent element for now
            state.current = e.target;
            applyFocusVisibleClass(state.current);
        }
    };
    // Make sure that when focus leaves the scope, the focus visible class is removed
    const blurListener = (e)=>{
        if (!e.relatedTarget || isHTMLElement(e.relatedTarget) && !scope.contains(e.relatedTarget)) {
            if (state.current) {
                removeFocusVisibleClass(state.current);
                state.current = undefined;
            }
        }
    };
    scope.addEventListener(KEYBORG_FOCUSIN, keyborgListener);
    scope.addEventListener('focusout', blurListener);
    scope.focusVisible = true;
    // Return disposer
    return ()=>{
        scope.removeEventListener(KEYBORG_FOCUSIN, keyborgListener);
        scope.removeEventListener('focusout', blurListener);
        delete scope.focusVisible;
        disposeKeyborg(keyborg);
    };
}
function applyFocusVisibleClass(el) {
    el.setAttribute(FOCUS_VISIBLE_ATTR, '');
}
function removeFocusVisibleClass(el) {
    el.removeAttribute(FOCUS_VISIBLE_ATTR);
}
function alreadyInScope(el) {
    if (!el) {
        return false;
    }
    if (el.focusVisible) {
        return true;
    }
    return alreadyInScope(el === null || el === void 0 ? void 0 : el.parentElement);
}
