import * as React from 'react';
export function useFluentProviderContextValues_unstable(state) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { applyStylesToPortals , // eslint-disable-next-line @typescript-eslint/naming-convention
    customStyleHooks_unstable , dir , root , targetDocument , theme , themeClassName , // eslint-disable-next-line @typescript-eslint/naming-convention
    overrides_unstable  } = state;
    const provider = React.useMemo(()=>({
            dir,
            targetDocument
        }), [
        dir,
        targetDocument
    ]);
    // "Tooltip" component mutates an object in this context, instance should be stable
    const [tooltip] = React.useState(()=>({}));
    return {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        customStyleHooks_unstable,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        overrides_unstable,
        provider,
        textDirection: dir,
        tooltip,
        theme,
        themeClassName: applyStylesToPortals ? root.className : themeClassName
    };
}
