import * as React from 'react';
/**
 * @internal
 */ const ProviderContext = React.createContext(undefined);
const providerContextDefaultValue = {
    targetDocument: typeof document === 'object' ? document : undefined,
    dir: 'ltr'
};
/**
 * @internal
 */ export const Provider = ProviderContext.Provider;
export function useFluent() {
    var _React_useContext;
    return (_React_useContext = React.useContext(ProviderContext)) !== null && _React_useContext !== void 0 ? _React_useContext : providerContextDefaultValue;
}
