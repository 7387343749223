import { useId, useIsomorphicLayoutEffect } from '@fluentui/react-utilities';
import * as React from 'react';
import { fluentProviderClassNames } from './useFluentProviderStyles.styles';
// String concatenation is used to prevent bundlers to complain with older versions of React
const useInsertionEffect = React['useInsertion' + 'Effect'] ? React['useInsertion' + 'Effect'] : useIsomorphicLayoutEffect;
const createStyleTag = (target, elementAttributes)=>{
    if (!target) {
        return undefined;
    }
    const tag = target.createElement('style');
    Object.keys(elementAttributes).forEach((attrName)=>{
        tag.setAttribute(attrName, elementAttributes[attrName]);
    });
    target.head.appendChild(tag);
    return tag;
};
const insertSheet = (tag, rule)=>{
    const sheet = tag.sheet;
    if (sheet) {
        if (sheet.cssRules.length > 0) {
            sheet.deleteRule(0);
        }
        sheet.insertRule(rule, 0);
    } else if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error('FluentProvider: No sheet available on styleTag, styles will not be inserted into DOM.');
    }
};
/**
 * Writes a theme as css variables in a style tag on the provided targetDocument as a rule applied to a CSS class
 * @internal
 * @returns CSS class to apply the rule
 */ export const useFluentProviderThemeStyleTag = (options)=>{
    const { targetDocument , theme , rendererAttributes  } = options;
    const styleTag = React.useRef();
    const styleTagId = useId(fluentProviderClassNames.root);
    const styleElementAttributes = rendererAttributes;
    const cssVarsAsString = React.useMemo(()=>{
        return theme ? Object.keys(theme).reduce((cssVarRule, cssVar)=>{
            cssVarRule += `--${cssVar}: ${theme[cssVar]}; `;
            return cssVarRule;
        }, '') : '';
    }, [
        theme
    ]);
    const rule = `.${styleTagId} { ${cssVarsAsString} }`;
    useHandleSSRStyleElements(targetDocument, styleTagId);
    useInsertionEffect(()=>{
        // The style element could already have been created during SSR - no need to recreate it
        const ssrStyleElement = targetDocument === null || targetDocument === void 0 ? void 0 : targetDocument.getElementById(styleTagId);
        if (ssrStyleElement) {
            styleTag.current = ssrStyleElement;
        } else {
            styleTag.current = createStyleTag(targetDocument, {
                ...styleElementAttributes,
                id: styleTagId
            });
            if (styleTag.current) {
                insertSheet(styleTag.current, rule);
            }
        }
        return ()=>{
            var _styleTag_current;
            (_styleTag_current = styleTag.current) === null || _styleTag_current === void 0 ? void 0 : _styleTag_current.remove();
        };
    }, [
        styleTagId,
        targetDocument,
        rule,
        styleElementAttributes
    ]);
    return {
        styleTagId,
        rule
    };
};
function useHandleSSRStyleElements(targetDocument, styleTagId) {
    // Using a state factory so that this logic only runs once per render
    // Each FluentProvider can create its own style element during SSR as a slot
    // Moves all theme style elements to document head during render to avoid hydration errors.
    // Should be strict mode safe since the logic is idempotent.
    React.useState(()=>{
        if (!targetDocument) {
            return;
        }
        const themeStyleElement = targetDocument.getElementById(styleTagId);
        if (themeStyleElement) {
            targetDocument.head.append(themeStyleElement);
        }
    });
}
