import * as React from 'react';
/**
 * Similar to `getSlots`, main difference is that it's compatible with new custom jsx pragma
 *
 * @internal
 * This is an internal temporary method, this method will cease to exist eventually!
 *
 * * ❗️❗️ **DO NOT USE IT EXTERNALLY** ❗️❗️
 */ export function getSlotsNext(state) {
    const slots = {};
    const slotProps = {};
    const slotNames = Object.keys(state.components);
    for (const slotName of slotNames){
        const [slot, props] = getSlotNext(state, slotName);
        slots[slotName] = slot;
        slotProps[slotName] = props;
    }
    return {
        slots,
        slotProps: slotProps
    };
}
function getSlotNext(state, slotName) {
    var _state_components, _state_components1;
    const props = state[slotName];
    if (props === undefined) {
        return [
            null,
            undefined
        ];
    }
    // TS Error: Property 'as' does not exist on type 'UnknownSlotProps | undefined'.ts(2339)
    const { as: asProp , ...propsWithoutAs } = props;
    const slot = ((_state_components = state.components) === null || _state_components === void 0 ? void 0 : _state_components[slotName]) === undefined || typeof state.components[slotName] === 'string' ? asProp || ((_state_components1 = state.components) === null || _state_components1 === void 0 ? void 0 : _state_components1[slotName]) || 'div' : state.components[slotName];
    const shouldOmitAsProp = typeof slot === 'string' && asProp;
    const slotProps = shouldOmitAsProp ? propsWithoutAs : props;
    return [
        slot,
        slotProps
    ];
}
