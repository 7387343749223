import * as React from 'react';
import { SLOT_RENDER_FUNCTION_SYMBOL } from '@fluentui/react-utilities';
export function createElement(type, props, ...children) {
    return hasRenderFunction(props) ? createElementFromRenderFunction(type, props, children) : React.createElement(type, props, ...children);
}
function createElementFromRenderFunction(type, props, overrideChildren) {
    const { [SLOT_RENDER_FUNCTION_SYMBOL]: renderFunction , ...renderProps } = props;
    if (overrideChildren.length > 0) {
        renderProps.children = React.createElement(React.Fragment, {}, ...overrideChildren);
    }
    return React.createElement(React.Fragment, {}, renderFunction(type, renderProps));
}
export function hasRenderFunction(props) {
    return Boolean(props === null || props === void 0 ? void 0 : props.hasOwnProperty(SLOT_RENDER_FUNCTION_SYMBOL));
}
